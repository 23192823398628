import React, { Component } from 'react'
import styled from 'styled-components'
import { HBTurq } from "../../../config/theme"
import { keyframes } from 'styled-components'
import TextTransition, { presets } from "react-text-transition";
import Timer from './Timer';

const targetFade = keyframes`
 0% { opacity: 1 }
 /* 50% { opacity: 0.5; } */
 100% { opacity: 0; } 
`


const KeyDisplayContainer = styled.div`
    /* border: 1px yellow dashed; */
    height: 300px;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-content: center;
`;

const KeyDisplay = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 180px;
    color: #fff;
    text-align: center;
    align-self: center;
    animation-name: ${targetFade};
    animation-duration: 0.03s;
    animation-iteration-count: 1;
    /* justify-self: center; */
`;

export default class Viewport extends Component {
    render() {        
        return (
            <KeyDisplayContainer>
                <Timer startTimer={this.props.startTimer} resetTimer={this.props.resetTimer} endTimer={this.props.endTimer}/>
                <KeyDisplay key={Math.random()}>{this.props.view}</KeyDisplay>
            </KeyDisplayContainer>
        )
    }
}


