import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './config';
import { Reset } from "styled-reset";
import { ThemeProvider } from 'styled-components';
import * as theme from "./config/theme"
// import BootNav from "./ui/component/App/NavBar";
import DvorakLayout from "./ui/component/Dvorak/DvorakLayout";

function App() {
  return (
    <div>
      <Reset />
      <ThemeProvider theme={theme}>
          <Router>
            <Switch>
                <Route path="/">
                  <DvorakLayout />
                </Route>
              </Switch>
          </Router>
      </ThemeProvider>
    </div>

  );
}

export default App;
