import Enum from 'enum'

// An enum of all available characters
export const KeyNum = new Enum([
    'esc',
    'tilda',
    'backtick',
    'exclamation',
    'one',
    "at",
    'two',
    'pound',
    'three',
    'dollar',
    'four',
    'percent',
    'five',
    'carat',
    'six',
    'ampersand',
    'seven',
    'asterisk',
    'eight',
    'leftparenthesis',
    'nine',
    'rightparanthesis',
    'zero',
    'leftbracket',
    'leftcurlybrace',
    'rightbracket',
    'rightcurlybrace',
    'backspace',
    'lefttab',
    'doublequote',
    'singlequote',
    'leftanglebrace',
    'comma',
    'rightanglebrace',
    'period',
    'p',
    'y',
    'f',
    'g',
    'c',
    'r',
    'l',
    'questionmark',
    'forwardslash',
    'plus',
    'equal',
    'pipe',
    'backslash',
    'capslock',
    'a',
    'o',
    'e',
    'u',
    'i',
    'd',
    'h',
    't',
    'n',
    's',
    'underscore',
    'dash',
    'return',
    'leftshift',
    'colon',
    'semicolon',
    'q',
    'j',
    'k',
    'x',
    'b',
    'm',
    'w',
    'v',
    'z',
    'rightshift',
    'leftcontrol',
    'leftwindows',
    'leftalt',
    'space',
    'rightalt',
    'rightwindows',
    'rightcontrol',
    'function',
    'leftoption',
    'leftcommand',
    'rightcommand',
    'rightoption'

])

export const KeyTarget = {


    [KeyNum.esc] : "",


    [KeyNum.esc] : "Esc",
    [KeyNum.tilda] : "~",
    [KeyNum.backtick] : "`",
    [KeyNum.exclamation] : "!",
    [KeyNum.one] : "1",
    [KeyNum.at] : "@",
    [KeyNum.two] : "2",
    [KeyNum.pound] : "#",
    [KeyNum.three] : "3",
    [KeyNum.dollar] : "$",
    [KeyNum.four] : "4",
    [KeyNum.percent] : "%",
    [KeyNum.five] : "5",
    [KeyNum.carat] : "^",
    [KeyNum.six] : "6",
    [KeyNum.ampersand] : "&",
    [KeyNum.seven] : "7",
    [KeyNum.asterisk] : "*",
    [KeyNum.eight] : "8",
    [KeyNum.leftparenthesis] : "(",
    [KeyNum.nine] : "9",
    [KeyNum.rightparanthesis] : ")",
    [KeyNum.zero] : "0",
    [KeyNum.leftbracket] : "[",
    [KeyNum.leftcurlybrace] : "{",
    [KeyNum.rightbracket] : "]",
    [KeyNum.rightcurlybrace] : "}",
    [KeyNum.backspace] : "Delete",
    [KeyNum.lefttab] : "L-Tab",
    [KeyNum.doublequote] : '"',
    [KeyNum.singlequote] : "'",
    [KeyNum.leftanglebrace] : "<",
    [KeyNum.comma] : ",",
    [KeyNum.rightanglebrace] : ">",
    [KeyNum.period] : ".",
    [KeyNum.p] : "p",
    [KeyNum.y] : "y",
    [KeyNum.f] : "f",
    [KeyNum.g] : "g",
    [KeyNum.c] : "c",
    [KeyNum.r] : "r",
    [KeyNum.l] : "l",
    [KeyNum.questionmark] : "?",
    [KeyNum.forwardlash] : "/",
    [KeyNum.plus] : "+",
    [KeyNum.equal] : "=",
    [KeyNum.pipe] : "|",
    [KeyNum.backslash] : "\\",
    [KeyNum.capslock] : "Caps",
    [KeyNum.a] : "a",
    [KeyNum.o] : "o",
    [KeyNum.e] : "e",
    [KeyNum.u] : "u",
    [KeyNum.i] : "i",
    [KeyNum.d] : "d",
    [KeyNum.h] : "h",
    [KeyNum.t] : "t",
    [KeyNum.n] : "n",
    [KeyNum.s] : "s",
    [KeyNum.underscore] : "_",
    [KeyNum.dash] : "-",
    [KeyNum.return] : "Return",
    [KeyNum.leftshift] : "L-Shift",
    [KeyNum.colon] : ":",
    [KeyNum.semicolon] : ";",
    [KeyNum.q] : "q",
    [KeyNum.j] : "j",
    [KeyNum.k] : "k",
    [KeyNum.x] : "x",
    [KeyNum.b] : "b",
    [KeyNum.m] : "m",
    [KeyNum.w] : "w",
    [KeyNum.v] : "v",
    [KeyNum.z] : "z",
    [KeyNum.rightshift] : "R-Shift",
    [KeyNum.leftcontrol] : "L-Ctrl",
    [KeyNum.leftwindows] : "L-Windows",
    [KeyNum.leftalt] : "L-Alt",
    [KeyNum.space] : "Spacebar",
    [KeyNum.rightalt] : "R-Alt",
    [KeyNum.rightwindows] : "R-Windows",
    [KeyNum.rightcontrol] : "R-Ctrl",
    [KeyNum.function] : "Function",
    [KeyNum.leftoption] : "L-Option",
    [KeyNum.leftcommand] : "L-Command",
    [KeyNum.rightcommand] : "R-Command",
    [KeyNum.rightoption] : "R-Option"
}



