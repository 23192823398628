import React, { useEffect  } from 'react'
import styled from 'styled-components'
import { useTimer } from 'use-timer';
import { HBTurq } from "../../../config/theme"


const ScoreRowContainer = styled.div`
    /* border: 1px blue dashed; */
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
`;

const ScoreContainer = styled.div`
    /* border: 1px green dashed; */
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
`;

const Label = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 15pt;
    color: #FFF;
    text-shadow: 1px 1px 4px #000;
    line-height: 40px;
    text-align: center;
`;

const Points = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16pt;
    color: #38F60A;
    text-shadow: 1px 1px 4px #000;
    line-height: 40px;
    text-align: center;
    `;

const TimerLabel = styled(Points)`
    font-size: 30pt;
    color: white;
    margin-top: 20px;
`; 

export default function Timer (props) {
    const { time, start, pause, reset, status } = useTimer({
        initialTime: 60,
        endTime: 0,
        timerType: 'DECREMENTAL',
        onTimeOver: () => {
            props.endTimer()
          },
      });


      // These fire when the props they watch change
      useEffect(() => {
        if(props.startTimer === true){
            reset()
            start()
        } else {
            reset()
            start()
        }
        }, [props.startTimer]);

        useEffect(() => {
            if(props.resetTimer === true){
               reset()
            }
          }, [props.resetTimer]);

    return (
      <>
                <ScoreRowContainer>
                    <ScoreContainer>
                        <TimerLabel>
                            {time}
                        </TimerLabel>
                    </ScoreContainer>
                </ScoreRowContainer>
      </>
    );
  };












