import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HBTurq } from './../../../../config/theme';
import { KeyNum } from '../Class/KeyNum'

const SquareKeyButton = styled.button`
    width: 83px;
    height: 74px;
    border-radius: 10px;
    background: 'white';
    border: none;
    box-shadow: 0px 0px 3px 'white';
    outline: none;
    display: flex;
    justify-self: center;
    align-content: center;
    justify-content: center;
    margin-right: 5px;
    margin-left: 5px;
`;

const PressedSquareKeyButton = styled(SquareKeyButton)`
    /* border: 3px solid ${props => 'blue' || 'blue' }; */
    /* border: 3px solid blue; */
    background-color: turquoise;
`;

const Text = styled.p`
    /* border: 1px green dashed; */
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 30px;
    color: #000;
    align-self: center;
    /* margin-top: 16px; */
`;


export default function SquareKey(props) {

    const [isPressed, setIsPressed] = useState(false)

       function onClick (){
            if(isPressed === false){
                // add key to array
                setIsPressed(true)
                props.addKeyNum(props.keyNum)
            } else {
                // remove key from array
                setIsPressed(false)
                props.removeKeyNum(props.keyNum)
            }       
    }

    useEffect(() => {
            setIsPressed(false) 
            if(props.keyNum === KeyNum.u || props.keyNum === KeyNum.h){
                setIsPressed(true)
                props.addKeyNum(props.keyNum)
            }
      }, [props.reset]);


    return (

            <SquareKeyButton  onClick={onClick}            
                style={{ background: props.fingerings ?  props.color :  isPressed ? 'turquoise' : 'white' }} >
                <Text>
                    {props.symbol}
                </Text>
            </SquareKeyButton>
    )
}


