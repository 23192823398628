import React, { Component } from 'react'
import styled from 'styled-components'
import TopRowKey from './../Key/TopRowKey';
import TabDeleteKey from './../Key/TabDeleteKey';
import SquareKey from './../Key/SquareKey';
import SpacebarKey from './../Key/SpacebarKey';
import WindowsKey from './../Key/WindowsKey';
import { KeyNum } from '../Class/KeyNum'


const Container = styled.div`
  /* border: 1px green dashed; */
    display: flex; 
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: start;
    margin-top: 10px;
    margin-left: 52px;
`;

export default class RowThird extends Component {
    render() {
        return (
            <Container>
                    <TabDeleteKey {...this.props} keyNum={KeyNum.leftcontrol} symbol={"Ctrl"} color={this.props.fingerings === true ? '#b384d3' : null}></TabDeleteKey>
                    <WindowsKey {...this.props} keyNum={KeyNum.leftwindows} color={this.props.fingerings === true ? '#b384d3' : null}/>
                    <TabDeleteKey {...this.props} keyNum={KeyNum.leftalt} symbol={"Alt"} color={this.props.fingerings === true ? ' #65e86f' : null}></TabDeleteKey>
                    <SpacebarKey {...this.props} keyNum={KeyNum.space} symbol={"Space"} color={this.props.fingerings === true ? ' #65e86f' : null}></SpacebarKey>
                    <TabDeleteKey {...this.props} keyNum={KeyNum.rightalt} symbol={"Alt"} color={this.props.fingerings === true ? ' #65e86f' : null}></TabDeleteKey>
                    <WindowsKey {...this.props} keyNum={KeyNum.rightwindows} symbol={"Windows"} color={this.props.fingerings === true ? ' #C3C7FF' : null}/>
                    <TabDeleteKey {...this.props} keyNum={KeyNum.rightcontrol} symbol={"Ctrl"} color={this.props.fingerings === true ? '#b384d3' : null}></TabDeleteKey>
            </Container>
        )
    }
}
