import React, { Component } from 'react'
import styled from 'styled-components'
import Image from './../../../../res/images/ic_windows.svg'
import { HBTurq } from './../../../../config/theme';

const SquareKeyButton = styled.button`
    width: 83px;
    height: 74px;
    border-radius: 10px;
    opacity: 0.2;
    background: ${props => props.color || 'white'};
    border: 1px solid ${props => props.color || 'white'};
    box-shadow: 0px 0px 3px ${props => props.color || 'white'};
    display: flex;
    justify-self: center;
    align-content: center;
    justify-content: center;
    margin-right: 5px;
    margin-left: 5px;
`;

const Icon = styled.img`
    width: 28px;
    height: 25px;
    background: transparent;
    align-self: center;
`;


export default function WindowsKey(props) {


    return (

            <SquareKeyButton color={props.color}>
                <Icon src={Image}>
                    
                </Icon>
            </SquareKeyButton>

    )
}
