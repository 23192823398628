import React, { Component } from 'react'
import styled from 'styled-components'
import { HBTurq } from "../../../config/theme"
import { Points } from './Points'
import { Accuracy } from './Accuracy'


const ScoreRowContainer = styled.div`
    /* border: 1px yellow dashed; */
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-content: center;
`;

const ScoreContainer = styled.div`
    /* border: 1px green dashed; */
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-left: 50px;
    margin-right: 8px;
    /* align-content: center; */
    /* justify-content: center; */
`;

const Label = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 15pt;
    color: #FFF;
    text-shadow: 1px 1px 4px #000;
    margin-bottom: 20px;
    /* line-height: 40px; */
    text-align: center;
`;

// const Points = styled.p`
//     font-family: 'Montserrat', sans-serif;
//     font-weight: 500;
//     font-size: 26pt;
//     color: #FFF;
//     text-shadow: 1px 1px 4px #000;
//     line-height: 40px;
//     text-align: center;
// `;


export default class Scoreboard extends Component {

    
    render() {
        return (
            <ScoreRowContainer>
                <ScoreContainer>
                    <Label>Points</Label>
                    <Points points={this.props.correct * 10}></Points>
                </ScoreContainer>
                <ScoreContainer>
                    <Label>Accuracy</Label>
                    <Accuracy 
                       accuracy= {this.props.attempts !== 0 ?                  
                            Math.round(this.props.correct / this.props.attempts * 100)
                        :
                            0
                        }
                        >
                    </Accuracy>
                </ScoreContainer>
            </ScoreRowContainer>
        )
    }
}
