import React, { Component } from 'react'
import styled from 'styled-components'
import TopRowKey from './../Key/TopRowKey';
import TabDeleteKey from './../Key/TabDeleteKey';
import SquareKey from './../Key/SquareKey';
import ShiftKey from './../Key/ShiftKey';
import { KeyNum } from '../Class/KeyNum'

const Container = styled.div`
  /* border: 1px green dashed; */
    display: flex; 
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
`;

export default class RowFourth extends Component {
    render() {
        return (
            <Container>
                    <ShiftKey {...this.props} keyNum={KeyNum.leftshift} symbol={"shift"} color={this.props.fingerings === true ? '#b384d3' : null}></ShiftKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.colon} keyNum2={KeyNum.semicolon} symboltop={":"} symbolbottom={";"} color={this.props.fingerings === true ? '#b384d3' : null}></TopRowKey>
                    <SquareKey {...this.props} keyNum={KeyNum.q} symbol={"q"} color={this.props.fingerings === true ? ' #C3C7FF' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.j} symbol={"j"} color={this.props.fingerings === true ? ' #63C5F8' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.k} symbol={"k"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.x} symbol={"x"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.b} symbol={"b"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.m} symbol={"m"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.w} symbol={"w"} color={this.props.fingerings === true ? ' #63C5F8' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.v} symbol={"v"} color={this.props.fingerings === true ? ' #C3C7FF' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.z} symbol={"z"} color={this.props.fingerings === true ? '#b384d3' : null}></SquareKey>
                    <ShiftKey {...this.props} keyNum={KeyNum.rightshift} symbol={"shift"} color={this.props.fingerings === true ? '#b384d3' : null}></ShiftKey>
            </Container>
        )
    }
}
