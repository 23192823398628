import React, { Component } from 'react'
import styled from 'styled-components'
import TopRowKey from './../Key/TopRowKey';
import TabDeleteKey from './../Key/TabDeleteKey';
import { KeyNum } from '../Class/KeyNum'

const Container = styled.div`
  /* border: 1px green dashed; */
    display: flex; 
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
`;

export default class TopRow extends Component {

    addKey = () => {
        
    }


    render() {
        return (
            <Container>
                    <TopRowKey {...this.props} keyNum={KeyNum.tilda} keyNum2={KeyNum.backtick} symboltop={"~"} symbolbottom={"`"} color={this.props.fingerings === true ? '#b384d3' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.exclamation} keyNum2={KeyNum.one} symboltop={"!"} symbolbottom={"1"} color={this.props.fingerings === true ? '#b384d3' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.at} keyNum2={KeyNum.two} symboltop={"@"} symbolbottom={"2"} color={this.props.fingerings === true ? ' #C3C7FF' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.pound} keyNum2={KeyNum.three} symboltop={"#"} symbolbottom={"3"} color={this.props.fingerings === true ? ' #C3C7FF' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.dollar} keyNum2={KeyNum.four} symboltop={"$"} symbolbottom={"4"} color={this.props.fingerings === true ? ' #63C5F8' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.percent} keyNum2={KeyNum.five} symboltop={"%"} symbolbottom={"5"} color={this.props.fingerings === true ? ' #f5f534' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.carat} keyNum2={KeyNum.six} symboltop={"^"} symbolbottom={"6"} color={this.props.fingerings === true ? ' #f5f534' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.ampersand} keyNum2={KeyNum.seven} symboltop={"&"} symbolbottom={"7"} color={this.props.fingerings === true ? ' #f5f534' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.asterisk} keyNum2={KeyNum.eight} symboltop={"*"} symbolbottom={"8"} color={this.props.fingerings === true ? ' #f5f534' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.leftparenthesis} keyNum2={KeyNum.nine} symboltop={"("} symbolbottom={"9"} color={this.props.fingerings === true ? ' #63C5F8' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.rightparanthesis} keyNum2={KeyNum.zero} symboltop={")"} symbolbottom={"0"} color={this.props.fingerings === true ? ' #C3C7FF' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.leftbracket} keyNum2={KeyNum.leftcurlybrace} symboltop={"["} symbolbottom={"{"} color={this.props.fingerings === true ? ' #C3C7FF' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.rightbracket} keyNum2={KeyNum.rightcurlybrace} symboltop={"]"} symbolbottom={"}"} color={this.props.fingerings === true ? '#b384d3' : null}></TopRowKey>
                    <TabDeleteKey {...this.props} keyNum={KeyNum.backspace} symbol={"delete"} color={this.props.fingerings === true ? '#b384d3;' : null}></TabDeleteKey>
            </Container>
        )
    }
}
