import React, { Component } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import { HBTurq } from "../../../config/theme"
import { KeyNum } from './Class/KeyNum';
import KeyboardLayout from './KeyboardLayout';
import ScreenLayout from './ScreenLayout';
import TagManager from 'react-gtm-module'
import VideoPlayer from './Onboard/VideoPlayer';
import MediaQuery from "react-responsive";
import { KeyTarget } from './Class/KeyNum';

const GridContainer = styled.div`
    display: grid;
    grid-template: auto auto / 100%;
    overflow: hidden;
    background: linear-gradient(#0a276a 0%, #060b1d 50%, #060b1d 100%);
    padding-top: 40px;
    padding-bottom: 40px;
    border: none;
    outline: none;
    ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
     /* display: none; */
  `}
  ${media.greaterThan("large")`
  `}
`;

const VideoContainer = styled.div`
    /* border: 1px red dashed; */
    width: auto;
    /* padding-top: 40px; */
    padding-bottom: 40px;
    justify-self: center;
`;

const UnavailableContainer = styled.div`
    border: 1px ${HBTurq} solid;
    box-shadow: 0px 0px 3px ${props => props.color || HBTurq };
    display: flex;
    flex-direction: column;
    background-color: black;
    border-radius: 15px;
    height: 300px;
    width: 300px;
    justify-content: center;
    align-content: center;
    justify-self: center;
`;

const UnavailableText = styled.p`
    /* height: 200px; */
    width: 260px;
    color: white;
    align-self: center;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 20px;
`;

const UnavailableTextTitle = styled(UnavailableText)`
    /* height: 200px; */
    font-weight: 600;
    font-size: 30px;
`;


// App should default to two keys
// Flash points green when correct

export default class DvorakLayout extends Component {

    state = {
        focus: false,
        forceReset: false,
        fingerings: false,
        keys: [],
        target: null,
        lastTarget: KeyNum.a,
        view: "Dvorak!",
        attempts: 0,
        correct: 0,
        showOnboard: false,
        startTimer: false,
        resetTimer: true,
        isPlaying: false
    }

    componentDidMount(){
        // TagManager.initialize(tagPageVisit)
    }

    endTimer = () => {
        this.setState({view: "Time", isPlaying: false})
    }

    logKeys = () => {
        let keysArray = []
        this.state.keys.forEach((item ) => {
            keysArray.push(item.key)
        })
        console.log("keysArray ", keysArray ) 
    }

    addKey = (key) => {
        let keysArray = this.state.keys
        keysArray.push(key)
        this.setState({ keys : keysArray})
    }

    removeKey = (key) => {
        let keysArray = this.state.keys
        const index = keysArray.indexOf(key);
        if (index > -1) {
            keysArray.splice(index, 1);
            this.setState({ keys : keysArray})
        }
    }

    start = () => {
        this.getUniqueRandomKey()
        this.setState({ attempts : 0, correct: 0, startTimer: !this.state.startTimer, resetTimer: false, isPlaying: true})
    }

    focus = () => {
        if(this.state.focus === false){
            this.setState({ focus : true, view: "Focus"})
        } else {
            this.setState({ focus : false})
        }
    }

    reset = () => {
        this.setState({ 
            keys : [], 
            forceReset: !this.state.forceReset, 
            view: "Dvorak!", 
            practice: false, 
            focus: false,
            target: null,
            lastTarget: KeyNum.a,
            attempts: 0,
            correct: 0,
            startTimer: false, 
            resetTimer: true,
            isPlaying: false
        })
    }

    fingerings = () => {
        if(this.state.fingerings === false){
            this.setState({ fingerings : true})
        } else {
            this.setState({ fingerings : false})
        }
    }

    getRandomKey = () => {
        let keysArray = this.state.keys
        keysArray.sort(() => (Math.random() > .5) ? 1 : -1)
        let [targetKey] = keysArray
        if (targetKey != null){
            this.setState({ view: KeyTarget[targetKey], target: targetKey})
        }
    }

    getUniqueRandomKey = () => {
        let keysArray = this.state.keys 
        let lastKey = this.state.lastTarget                         
        let currentKey = null
        
        do {            
            keysArray.sort(() => (Math.random() > .5) ? 1 : -1)
            currentKey = keysArray[0]        
        } while ( currentKey === lastKey  )
    
        lastKey = currentKey

        if (currentKey != null){
            this.setState({ view: KeyTarget[currentKey], target: currentKey, lastTarget: lastKey})
        }
    }

    userKeyPress = (e) => {

        if(this.state.isPlaying === true){
            let localCorrect = this.state.correct
            let localAttempts = this.state.attempts

            localAttempts = localAttempts + 1
            
            const target = KeyTarget[this.state.target]
            
            if(e.key == target){
                localCorrect = localCorrect + 1
                
                if (this.state.keys.length === 2) {
                    this.getRandomKey()
                }  else {
                    this.getUniqueRandomKey()
                }
            } 
            this.setState({ correct: localCorrect, attempts: localAttempts})
        } 
    }

    showOnboardVideo = () => {
        this.setState(prevState => ({
            showOnboard: !prevState.showOnboard
        }));
      };
      


    render() {
        // this.logKeys()
        return (

            <GridContainer
                onKeyPress={this.userKeyPress}  
                tabIndex={0} 
            >

            <MediaQuery minWidth={1224}>
                    <ScreenLayout 
                        practice={this.start}
                        focus={this.focus}
                        isFocused={this.state.focus}
                        reset={this.reset}
                        fingerings={this.fingerings}
                        isFingerings={this.state.fingerings}
                        view={this.state.view}
                        correct={this.state.correct}
                        attempts={this.state.attempts}
                        handleOnboard={this.showOnboardVideo}
                        onboard={this.state.showOnboard}
                        startTimer={this.state.startTimer}
                        resetTimer={this.state.resetTimer}
                        endTimer={this.endTimer}
                    />

                    {this.state.showOnboard ? 
                        <VideoContainer>
                            <VideoPlayer/>
                        </VideoContainer>
                        : 
                        <KeyboardLayout 
                        addKeyNum={this.addKey}
                        removeKeyNum={this.removeKey}
                        fingerings={this.state.fingerings} 
                        reset={this.state.forceReset}
                    />
                }
                </MediaQuery>

                <MediaQuery maxWidth={1224}>
                    <UnavailableContainer>
                        <UnavailableTextTitle>DVORAK!</UnavailableTextTitle>
                        <UnavailableText>Larger screen required</UnavailableText>
                    </UnavailableContainer>
                </MediaQuery>  

            </GridContainer>
        )
    }
}

const tagPageVisit = {
    gtmId: global.config.gtmId,
    dataLayer: {
        tag: 'dvorak_page_visit'
    }
  }


// https://codesandbox.io/s/o43839o5r6?file=/src/index.js:279-288

