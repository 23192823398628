import React, { Component } from 'react'
import styled, { keyframes, css } from "styled-components";
import { HBTurq } from "../../../config/theme"
import TagManager from 'react-gtm-module'

const ButtonsContainer = styled.div`
    /* border: 1px red dashed; */
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    /* margin-left: 60px; */
    justify-content: center;
`;

const Button = styled.button`
    /* border: 1px blue dashed; */
    outline: none;
    width: 150px;
    height: 45px;
    color: ${HBTurq};
    background: black;
    border: 2px solid ${HBTurq};
    border-radius: 30px;
    box-shadow: 0px 0px 5px ${HBTurq};
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 14px;
    align-self: center;
    margin-top: 6px;
    margin-bottom: 6px;
    transition-duration: 0.5s;
    &:hover{
        /* opacity: 0.9; */
        transform: scale(1.05);
        }
`;

export default class Controller extends Component {

    practice = () => {
        // TagManager.initialize(tagStartDvorak)
        this.props.practice()
    }

    focus = () => {
        this.props.focus()
    }

    reset = () => {
        this.props.reset()
    }

    fingerings = () => {
        this.props.fingerings()
    }

    render() {

        return (
            <ButtonsContainer>
                    <Button onClick={this.practice}>Start</Button>
                    <Button onClick={this.reset} >Reset</Button>
                    <Button onClick={this.fingerings} 
                    style={this.props.isFingerings ? {background: HBTurq, color: 'black', fontWeight: 600}   :  {background: 'black', color: HBTurq, fontWeight: 500} }>Fingerings</Button>
            </ButtonsContainer>
        )
    }
}

const tagStartDvorak = {
gtmId: global.config.gtmId,
dataLayer: {
  tag: 'dvorak_round_start'
}
}



// {this.props.isFocused ? 
//     <PressedButton onClick={this.focus} >Focus</PressedButton>
// :
//     <Button onClick={this.focus} >Focus</Button>
// }