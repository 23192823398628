import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const SquareKeyButton = styled.button`
    width: 83px;
    height: 74px;
    border-radius: 10px;
    background: 'white';
    border: none;
    box-shadow: 0px 0px 3px 'white';
    outline: none;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    margin-right: 5px;
    margin-left: 5px;
`;

const TextTop = styled.p`
    /* border: 1px green dashed; */
    border-radius: 10px;
    height: 50%;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 24px;
    color: #000;
    align-self: center;
    text-align: center;
    margin: 0px auto;
    padding-top: 6px;
`;

const TextBottom = styled.p`
    /* border: 1px red dashed; */
    border-radius: 10px;
    height: 50%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 24px;
    width: 100%;
    color: #000;
    align-self: center;
    margin: 0px auto;
    padding-top: 5px;
`;

const PressedTextBottom = styled(TextBottom)`
    background-color: orange;
`;

const KeyContainer = styled.div`
    /* border: 1px green dashed; */
    width: 100%;
    height: 100%;
`

export default function TopRowKey(props) {

const [isPressed, setIsPressed] = useState(false)
const [keyPressed, setKeyPressed] = useState(0)

   const onClick = () => {
    if(keyPressed === 0){
        setIsPressed(true)
        setKeyPressed(1)
        props.addKeyNum(props.keyNum)
    } else if (keyPressed === 1){
        setKeyPressed(2)
        props.removeKeyNum(props.keyNum)
        props.addKeyNum(props.keyNum2)
    } else if (keyPressed === 2){
        setKeyPressed(3)
        props.addKeyNum(props.keyNum)
    } else {
        props.removeKeyNum(props.keyNum2)
        props.removeKeyNum(props.keyNum)
        setKeyPressed(0)
        setIsPressed(false)
    }     
}

        useEffect(() => {
            setKeyPressed(0)
        }, [props.reset]);

    return (
            <SquareKeyButton onClick={onClick}  style={{ background: props.fingerings ?  props.color : 'white'}} >
                    <TextTop style={{ background: props.fingerings ?  null :  keyPressed === 1 || keyPressed === 3  ? 'turquoise' : 'white' }} >
                        {props.symboltop}
                    </TextTop>
                    <TextBottom style={{ background: props.fingerings ?  null :  keyPressed === 2 || keyPressed === 3  ? 'orange' : 'white' }}>
                        {props.symbolbottom}
                    </TextBottom>
            </SquareKeyButton>
    )
}
