import React, { Component } from 'react'
import styled from 'styled-components'
import { HBTurq } from "../../../config/theme"
import RowTop from './Row/RowTop';
import RowSecond from './Row/RowSecond';
import RowThird from './Row/RowThird';
import RowFourth from './Row/RowFourth';
import RowBottom from './Row/RowBottom'

const Grid = styled.div`
    grid-area: 2 / 1 / 3 / 2;
    width: 1440px;
    height: 460px;
    border: 3px white solid;
    border-radius: 52px;
    background: #0a0909;
    display: flex;
    flex-direction: column;
    justify-self: center;
    margin-top: 0px;
`;

export default class KeyboardLayout extends Component {
    render() {
        return (
            <Grid>
                <RowTop {...this.props} fingerings={this.props.fingerings} add={this.props.add} remove={this.props.remove}/>
                <RowSecond {...this.props} fingerings={this.props.fingerings} add={this.props.add} remove={this.props.remove} />
                <RowThird {...this.props} fingerings={this.props.fingerings} add={this.props.add} remove={this.props.remove} />
                <RowFourth {...this.props} fingerings={this.props.fingerings} add={this.props.add} remove={this.props.remove} />
                <RowBottom {...this.props} fingerings={this.props.fingerings} add={this.props.add} remove={this.props.remove}  />
            </Grid>
        )
    }
}
