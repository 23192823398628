import React, { Component } from 'react'
import styled from 'styled-components'
import TopRowKey from './../Key/TopRowKey';
import TabDeleteKey from './../Key/TabDeleteKey';
import SquareKey from './../Key/SquareKey';
import { KeyNum } from '../Class/KeyNum'

const Container = styled.div`
  /* border: 1px green dashed; */
    display: flex; 
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
`;

export default class RowSecond extends Component {

    render() {

        return (
            <Container>
                    <TabDeleteKey {...this.props} keyNum={KeyNum.lefttab} symbol={"tab"} color={this.props.fingerings === true ? '#b384d3' : null}></TabDeleteKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.doublequote} keyNum2={KeyNum.singlequote}  symboltop={"\""} symbolbottom={"'"} color={this.props.fingerings === true ? '#b384d3' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.leftanglebrace} keyNum2={KeyNum.comma}  symboltop={"<"} symbolbottom={","} color={this.props.fingerings === true ? ' #C3C7FF' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.rightanglebrace} keyNum2={KeyNum.period}  symboltop={">"} symbolbottom={"."} color={this.props.fingerings === true ? ' #63C5F8' : null}></TopRowKey>
                    <SquareKey {...this.props} keyNum={KeyNum.p} keyNum2={KeyNum.dash}  symbol={"p"}  color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.y} symbol={"y"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.f} symbol={"f"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.g} symbol={"g"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.c} symbol={"c"} color={this.props.fingerings === true ? ' #63C5F8' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.r} symbol={"r"} color={this.props.fingerings === true ? ' #C3C7FF' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.l} symbol={"l"} color={this.props.fingerings === true ? ' #C3C7FF' : null}></SquareKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.questionmark} keyNum2={KeyNum.forwardslash}  symboltop={"?"} symbolbottom={"/"} color={this.props.fingerings === true ? '#b384d3' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.plus} keyNum2={KeyNum.equal}  symboltop={"+"} symbolbottom={"="} color={this.props.fingerings === true ? '#b384d3' : null}></TopRowKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.pipe} keyNum2={KeyNum.backslash}  symboltop={"|"} symbolbottom={"\\"} color={this.props.fingerings === true ? '#b384d3' : null}></TopRowKey>
            </Container>
        )
    }
}
