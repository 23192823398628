import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import Aos from "aos";
import "aos/dist/aos.css";
import ReactPlayer from 'react-player'
import TagManager from 'react-gtm-module'

const ReactPlayerContainer = styled.div`
    /* border: 1px red dashed; */
    display: flex;
    justify-content: center;
    align-self: center;

  ${media.lessThan("medium")`
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const Grid = styled.div`
    display: flex;
    width: 1440px;
    height: 460px;
    border: 3px white solid;
    border-radius: 52px;
    background: #0a0909;
    /* flex-direction: column; */
    justify-content: center;
    align-self: center;
    justify-self: center;
    margin-top: 0px;
    padding-top: 15px;
`;


export default class VideoPlayer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      playing: false,
    }
  }

  componentDidMount() {
    Aos.init({ duration: 2000 });
  }

  onPlay = () => {
    TagManager.initialize(tagOnboardDvorak)
    this.setState({ playing: true });
  }

  onPause = () => {
    this.setState({ playing: false });
  }

  render() {
    return (
        <Grid>
          <ReactPlayer
            width='824px'
            height='420px'
            controls
            url='https://vimeo.com/640076732'
            playing={this.state.playing}
            onReady={() => console.log('onReady callback')}
            onEnded={() => console.log('onEnded callback')}
            onError={() => console.log('onError callback')}
            onPause={this.onPause}
            onPlay={this.onPlay}
          />
        </Grid>
    )
  }
}
;


const tagOnboardDvorak = {
  gtmId: global.config.gtmId,
  dataLayer: {
    tag: 'dvorak_onboard_play'
  }
  }