import React, { Component } from 'react'
import styled from 'styled-components'
import { HBTurq } from "../../../config/theme"
import Controller from './Controller';
import Viewport from './Viewport';
import Scoreboard from './Scoreboard';




const Grid = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    width: 1440px;
    height: 300px;
    border: 3px white solid;
    border-radius: 63px;
    background: radial-gradient(#0a276a 0%, #060b1d 50%, #060b1d 100%);
    display: flex;
    flex-direction: row;
    justify-self: center;
    /* margin-top: 5px; */
    /* justify-content: center; */
`;

const RightContainer = styled.div`
    /* border: 1px red dashed; */
    height: 90%;
    width: 20%;
    display: flex;
    margin-right: 30px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
`;

const ToggleContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex; 
    flex-direction: row;
    /* border: 1px red dashed; */
    align-self: flex-end;
    margin-bottom: 30px;
    margin-right: 15px;
    margin-top: 7px;
`;

const Button = styled.button`
    /* border: 1px blue dashed; */
    outline: none;
    width: 35px;
    height: 35px;
    color: white;
    background: black;
    border: 2px solid white;
    border-radius: 30px;
    box-shadow: 0px 0px 5px white;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 18px;
    align-self: center;
    margin-top: 6px;
    margin-bottom: 6px;
    transition-duration: 0.5s;
    &:hover{
        /* opacity: 0.9; */
        transform: scale(1.05);
        }
`;

export default class ScreenLayout extends Component {


    handleChange = () => {
        this.props.handleOnboard()
      };


    render() {
        return (
            <Grid>
       
                <Controller 
                    practice={this.props.practice}
                    focus={this.props.focus}
                    isFocused={this.props.isFocused}
                    reset={this.props.reset}
                    fingerings={this.props.fingerings}
                    isFingerings={this.props.isFingerings}
                />
                <Viewport view={this.props.view} startTimer={this.props.startTimer} resetTimer={this.props.resetTimer} endTimer={this.props.endTimer}/>
                <RightContainer>

                    <ToggleContainer>

                    <Button onClick={this.handleChange} 
                    style={this.props.onboard ? {background: HBTurq, color: 'black', fontWeight: 600, border: '2px solid #6EFAFF'}   :  {background: 'black', color: 'white', fontWeight: 500} }>
                        ?
                    </Button>
                    </ToggleContainer>

                    <Scoreboard
                    correct={this.props.correct}
                    attempts={this.props.attempts}
                    />
                </RightContainer>
            </Grid>
        )
    }
}



{/* <Question onClick={this.showOnboard}>?</Question> */}