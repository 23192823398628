import React from "react";
import styled from 'styled-components'
import TextTransition, { presets } from "react-text-transition";

const Text = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 45px;
    color: #FFF;
    text-shadow: 1px 1px 4px #000;
    align-self: center;
`;

export const Accuracy = (props) => {

  return (
    <Text>
      <TextTransition
        text={props.accuracy + "%"}
        springConfig={ presets.wobbly }
      />
    </Text>
  );
};