import React, { Component } from 'react'
import styled from 'styled-components'
import { HBTurq } from './../../../../config/theme';

const ShiftKeyButton = styled.div`
    width: 190px;
    height: 74px;
    border-radius: 10px;
    opacity: 0.2;
    background: ${props => props.color || 'white'};
    border: 1px solid ${props => props.color || 'lightgrey'};
    box-shadow: 0px 0px 5px ${props => props.color || 'white'};
    display: flex;
    flex-direction: column;
    justify-self: center;
    margin-right: 5px;
    margin-left: 5px;
`;

const Text = styled.p`
    /* border: 1px green dashed; */
    /* height: 100%; */
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #000;
    align-self: center;
    margin-top: 30px;
`;


export default function ShiftKey(props) {

    return (

            <ShiftKeyButton color={props.color}>
                <Text>
                    {props.symbol}
                </Text>
            </ShiftKeyButton>

    )
}
