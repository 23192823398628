import React, { Component } from 'react'
import styled from 'styled-components'
import TopRowKey from './../Key/TopRowKey';
import TabDeleteKey from './../Key/TabDeleteKey';
import SquareKey from './../Key/SquareKey';
import CapsEnterKey from './../Key/CapsEnterKey';
import { KeyNum } from '../Class/KeyNum'

const Container = styled.div`
  /* border: 1px green dashed; */
    display: flex; 
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
`;

export default class RowThird extends Component {



    render() {
        return (
            <Container>
                    <CapsEnterKey {...this.props} keyNum={KeyNum.caps}  symbol={"caps"} color={this.props.fingerings === true ? '#b384d3' : null}></CapsEnterKey>
                    <SquareKey {...this.props} keyNum={KeyNum.a} symbol={"a"} color={this.props.fingerings === true ? '#b384d3' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.o} symbol={"o"} color={this.props.fingerings === true ? ' #C3C7FF' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.e} symbol={"e"} color={this.props.fingerings === true ? ' #63C5F8' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.u} symbol={"u_"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.i} symbol={"i"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.d} symbol={"d"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.h} symbol={"_h"} color={this.props.fingerings === true ? ' #f5f534' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.t} symbol={"t"} color={this.props.fingerings === true ? ' #63C5F8' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.n} symbol={"n"} color={this.props.fingerings === true ? ' #C3C7FF' : null}></SquareKey>
                    <SquareKey {...this.props} keyNum={KeyNum.s} symbol={"s"} color={this.props.fingerings === true ? '#b384d3' : null}></SquareKey>
                    <TopRowKey {...this.props} keyNum={KeyNum.underscore} keyNum2={KeyNum.dash}  symboltop={"_"} symbolbottom={"-"} color={this.props.fingerings === true ? '#b384d3' : null}></TopRowKey>
                    <CapsEnterKey {...this.props} keyNum={KeyNum.return} symbol={"return"} color={this.props.fingerings === true ? '#b384d3' : null}></CapsEnterKey>
            </Container>
        )
    }
}


