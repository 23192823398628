import React from "react";
import styled from 'styled-components'
import { keyframes } from 'styled-components'
import TextTransition, { presets } from "react-text-transition";
import { HBTurq } from "../../../config/theme"


const fadeColor = keyframes`
 /* 0% { color: ${HBTurq}; }
 30% { color: ${HBTurq}; opacity: 1 }
 40% { color: ${HBTurq}; opacity: 0.5; }
 100% { color: ${HBTurq}; opacity: 0.1; } */
`

const Text = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 45px;
    color: white;
    text-shadow: 1px 1px 4px #000;
    align-self: center;
    /* animation-name: ${fadeColor};
    animation-duration: 2s;
    animation-iteration-count: 1; */
`;


export const Points = (props) => {
  return (
    <Text>
      <TextTransition
        text={props.points}
        springConfig={ presets.gentle }
      />
    </Text>
  );
};